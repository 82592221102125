<template>
    <v-main fluid class="mt-n6">
        <v-container class="p-0 px-2">
            <v-row class="d-flex justify-content-center">
                <v-col cols="12" sm="10" md="8" lg="5">
                    <div class="bg-white border p-5 mt-5">
                        <h1 class="display-3">{{$t("RL_CreateAccount")}}</h1>
                        <p class="caption">{{$t("IM_OnlyOurCurrentSuppliers")}}</p>
                        <hr />
                        <v-text-field id="email"
                                      ref="email"
                                      autocomplete="username"
                                      v-model="model.email"
                                      v-bind:error="errors.email"
                                      dense
                                      outlined
                                      hide-details
                                      color="accent"
                                      append-icon="email"
                                      type="email"
                                      v-bind:placeholder="$t('FL_Email')"
                                      :label="$t('FL_Email')"
                                      class="mb-3"></v-text-field>

                        <v-text-field id="password"
                                      ref="password"
                                      autocomplete="new-password"
                                      v-model="modelpassword"
                                      v-bind:error="errors.password"
                                      :label="$t('FL_Password')"
                                      dense
                                      outlined
                                      hide-details
                                      color="accent"
                                      v-bind:append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                      v-on:click:append="showpassword = !showpassword"
                                      v-bind:type="showpassword ? 'text' : 'password'"
                                      v-bind:placeholder="$t('FL_Password')"
                                      v-on:focus="SetSnackbar('password', 1)"
                                      v-on:focusout="SetSnackbar('password', 0)"
                                      class="mb-3"></v-text-field>

                        <v-text-field id="confirmpassword"
                                      ref="confirmpassword"
                                      autocomplete="new-password"
                                      v-model="modelconfirmpassword"
                                      v-bind:error="errors.confirmpassword"
                                      :label="$t('FL_PasswordAgain')"
                                      dense
                                      outlined
                                      hide-details
                                      color="accent"
                                      v-bind:append-icon="showconfirmpassword ? 'mdi-eye' : 'mdi-eye-off'"
                                      v-on:click:append="showconfirmpassword = !showconfirmpassword"
                                      v-bind:type="showconfirmpassword ? 'text' : 'password'"
                                      v-bind:placeholder="$t('FL_PasswordAgain')"
                                      v-on:focus="SetSnackbar('confirmpassword', 1)"
                                      v-on:focusout="SetSnackbar('confirmpassword', 0)"
                                      class="mb-3"></v-text-field>

                        <v-row class="my-3 d-flex justify-content-center align-items-center">
                            <v-col cols="auto" class="mr-0 pr-0">
                                <v-img id="captchaimg" height="106" width="300" :src="captchaUrl">
                                    <template v-slot:placeholder>
                                        <v-skeleton-loader class="my-auto"
                                                           type="image" height="106" width="300"></v-skeleton-loader>
                                    </template>
                                </v-img>
                            </v-col>
                            <v-col cols="auto">
                                <v-btn depressed icon v-on:click="ReloadCaptcha()" color="secondary">
                                    <v-icon>cached</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        <v-text-field v-model="model.captcha" v-bind:error="errors.captcha" dense outlined hide-details color="accent" append-icon="mdi-shield-check"
                                      v-bind:placeholder="$t('FL_SecurityCode')"
                                      :label="$t('FL_SecurityCode')"
                                      class="mb-5"></v-text-field>

                        <v-btn depressed tile color="primary white--text" v-on:click="Register()">{{$t("RL_CreateAccount")}}</v-btn>

                        <hr />
                        <br />
                        <p><router-link to="KayitTalebi">{{$t("AL_ApplicationAkbankSupplier")}}</router-link></p>
                    </div>
                </v-col>
            </v-row>
        </v-container>

        <v-snackbar light elevation="1" right top timeout="-1" width="400" v-model="snackbar.password">
            <div class="pt-1">
                <p class="text-uppercase">{{$t("FL_Password")}}</p>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title style="text-transform: capitalize" v-for="(item, i) in PassRequirementsMessages.password" v-bind:key="i" v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                <v-icon left v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                    {{item.satisfy ? 'mdi-check' : 'mdi-close'}}
                                </v-icon>
                                {{item.text}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
        </v-snackbar>

        <v-snackbar light elevation="1" right top timeout="-1" width="400" v-model="snackbar.confirmpassword">
            <div class="pt-1">
                <p class="text-uppercase">{{$t("FL_PasswordAgain")}}</p>
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title style="text-transform: capitalize" v-for="(item, i) in PassRequirementsMessages.confirmpassword" v-bind:key="i" v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                <v-icon left v-bind:class="item.satisfy ? 'green--text' : 'red--text'">
                                    {{item.satisfy ? 'mdi-check' : 'mdi-close'}}
                                </v-icon>
                                {{item.text}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </div>
        </v-snackbar>

        <v-dialog v-model="processing" persistent>
            <v-card color="transparent" dark class="py-5">
                <v-card-text class="text-center py-5">
                    <v-progress-circular indeterminate
                                         :size="50"
                                         color="white"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>

<script>
    import { FormMixIn } from '@/MixIns/FormMixins';
    export default {
        name: "Register",
        mixins: [FormMixIn],
        data: function ()
        {
            return {
                snackbar: {
                    password: false,
                    confirmpassword: false
                },

                processing: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                imgURL: '',

                modelpassword: '',
                modelconfirmpassword: '',

                model: {
                    email: '',
                    password: '',
                    confirmpassword: '',
                    captcha: ''
                },

                errors: {
                    email: false,
                    password: false,
                    confirmpassword: false,
                    captcha: false
                },
                keys: {
                    'email': 'FL_Email',
                    'password': 'FL_Password',
                    'confirmpassword': 'FL_PasswordAgain',
                    'captcha': 'FL_SecurityCode'
                },
                ErrorText: [],
                isAnyError: false,
                customErrorText: [],

                showpassword: false,
                showconfirmpassword: false,

                PassRequirementsMessages:
                {
                    password: [
                        { text: this.$root.$i18n.t("VE_MustContainLeast6Characters", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /.{6,}/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneSpecialCharacter", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /[!@#\$%\^\&*\)\(+=._-]/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneLowercaseLetter", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /[a-z]/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneUppercaseLetter", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /[A-Z]/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneNumber", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /\d/ }
                    ],
                    confirmpassword: [
                        { text: this.$root.$i18n.t("VE_MustContainLeast6Characters", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /.{6,}/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneSpecialCharacter", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /[!@#\$%\^\&*\)\(+=._-]/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneLowercaseLetter", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /[a-z]/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneUppercaseLetter", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /[A-Z]/ },
                        { text: this.$root.$i18n.t("VE_MustContainLeastOneNumber", { field: this.$root.$i18n.t('FL_Password') }), satisfy: false, regx: /\d/ }
                    ]
                },


                pageInputs: {
                    'Register': ['email', 'password', 'confirmpassword', 'captcha'],
                },
                captchaUrl: '/pages/BlankCaptcha.bmp',
            };
        },
        watch: {
            modelpassword: function (newVal, oldVal)
            {
                this.model.password = newVal;

                for (let requirement of this.PassRequirementsMessages.password)
                {
                    requirement.satisfy = requirement.regx.test(newVal);
                }
            },
            modelconfirmpassword: function (newVal, oldVal)
            {
                this.model.confirmpassword = newVal;

                for (let requirement of this.PassRequirementsMessages.confirmpassword)
                {
                    requirement.satisfy = requirement.regx.test(newVal);
                }
            },
        },
        methods: {
            SetSnackbar(input, value)
            {
                this.snackbar[input] = value == 1;
            },
            Register()
            {
                if (this.ValidateBeforeAction()) {
                    this.processing = true;
                    var payload = {
                        action: 'Register',
                        formdata: {
                            formInputs: { email: this.model.email, password: this.model.password, confirmpassword: this.model.confirmpassword },
                        },
                        captcha: this.model.captcha,
                        controller: 'Login',
                        method: 'Create'
                    };
                    var self = this;
                    this.$store.dispatch("AccountAction", payload)
                        .then(response => {
                            this.processing = false;
                            if (response.data.errorCode == 0) {
                                var messages = {
                                    errors: [this.$root.$i18n.t(response.data.result.alertcontent)],
                                    infoBody: response.data.result.alerttitle,
                                    messageType: 'B',
                                    viewType: 'dialog',
                                    OnCloseDialog: function () {
                                        this.$router.push({ name: 'login', });
                                    }
                                };

                                this.ClearModel();
                                this.$root.$emit('setUpDialog', messages);
                            }
                            else {
                                self.ShowDialog(response.data.errorMessage, 'E', 'dialog');
                                this.imgURL = this.ReloadCaptcha();
                                this.model.captcha = '';
                            }
                        })
                }
                else
                {
                    var messages = {
                        errors: this.customErrorText,
                        infoBody: '',
                        messageType: 'E',
                        viewType: 'dialog',
                    };
                    this.$root.$emit('setUpDialog', messages);
                }
            },
            ValidateBeforeAction: function ()
            {
                this.ClearErrors();
                for (var ctrl in this.errors)
                {
                    var data = this.model[ctrl];

                    if (ctrl == 'email')
                    {
                        this.errors[ctrl] = (data == '');

                        if (!this.errors[ctrl])
                        {
                            var isEmailValidated = this.ValidateEmail(this.model.email);

                            if (!isEmailValidated)
                                this.customErrorText.push(this.$t('VE_WarnEmailMustBeInRightFormat'));

                            this.errors[ctrl] = (!isEmailValidated);
                        }
                    }
                    else if (ctrl == 'password')
                    {
                        this.CustomPwdReqValidate(ctrl, data);
                    }
                    else if (ctrl == 'confirmpassword')
                    {
                        this.errors[ctrl] = (data == '');

                        if (!this.errors['password'])
                        {
                            if (!this.errors[ctrl])
                            {
                                this.CustomPwdReqValidate(ctrl, data);
                            }

                            if (!this.errors[ctrl])
                            {
                                if (data != this.model.password)
                                {
                                    this.customErrorText.push(this.$t('VE_PasswordsDontMatch'));
                                    this.errors[ctrl] = true;
                                }
                            }
                        }
                    }
                    else
                    {
                        this.errors[ctrl] = (data == '');
                    }
                }

                this.PrepareErrorMessage(this.pageInputs['Register']);

                return this.customErrorText.length == 0;
            },
            CustomPwdReqValidate(ctrl, data)
            {
                this.errors[ctrl] = (data == '');

                if (!this.errors[ctrl])
                {
                    for (let requirement of this.PassRequirementsMessages[ctrl])
                    {
                        if (!requirement.satisfy)
                        {
                            this.customErrorText.push(this.$t('Password') + ' ' + this.$t(requirement.text));
                            this.errors[ctrl] = true;
                        }
                    }
                }
            },
            ValidateEmail: function (value)
            {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },
            ClearErrors: function ()
            {
                this.customErrorText = [];

                for (var reset in this.errors)
                    this.errors[reset] = false;
            },
            PrepareErrorMessage: function (pageInputs)
            {
                for (var err in this.errors) {
                    if (pageInputs.indexOf(err) > -1) {
                        if (this.errors[err] == true) {
                            if (this.model[err] == '')
                                this.customErrorText.push(this.$root.$i18n.t('VE_WarnFieldCannotBeNull', { field: this.$root.$i18n.t(this.keys[err]) }));
                        }
                    }
                }
            },
            ClearModel()
            {
                this.model.email = '';
                this.model.password = '';
                this.model.confirmpassword = '';
                this.model.captcha = '';
                this.modelpassword = '';
                this.modelconfirmpassword = '';
            },
            ReloadCaptcha()
            {
                var date = Math.floor(new Date().getTime() / 1000).toString();
                this.captchaUrl = "/api/Captcha?rnd4=" + date;
                //this.$nextTick(() => this.captchaUrl = "/api/Captcha?rnd=" + Math.floor(new Date().getTime()).toString());
            }
        },
        mounted: function ()
        {
            //this.ReloadCaptcha();
            window.setTimeout(this.ReloadCaptcha, 1000);
        },
    }
</script>